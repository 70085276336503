import { useEffect } from 'react';
import { drawCanvas } from 'helpers/faceTracker';

export const useCanvasRendering = (canvasRef, videoRef, mirror, rotation, style) => {
  useEffect(() => {
    const drawFrame = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (video?.readyState === video.HAVE_ENOUGH_DATA) {
        drawCanvas({ canvas, video, mirror, rotation, style });
      }
      requestAnimationFrame(drawFrame);
    };

    drawFrame();

    return () => cancelAnimationFrame(drawFrame);
  }, [canvasRef, videoRef, mirror, rotation, style]);
};
