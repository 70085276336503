import { useEffect, useState } from "react";
import {
  adjustLandmarksForRotation,
  calculateFaceWidth,
  calculateFaceHeight,
  resetImagePosition,
  animateImage,
} from "helpers/face-detection-utils";
import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";

export const useFaceDetection = ({
  videoRef,
  canvasRef,
  imageRef,
  rotation,
  mirror,
  markRotation,
  sendLog,
  player,
}) => {
  const [detector, setDetector] = useState(null);
  const [isFaceDetected, setIsFaceDetected] = useState(false);

  useEffect(() => {
    const initializeFaceDetector = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
      );
      const faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
        },
        outputFaceBlendshapes: true,
        runningMode: "VIDEO",
        numFaces: 1,
        min_face_detection_confidence: 0.4,
      });
      setDetector(faceLandmarker);
    };
    initializeFaceDetector();
  }, []);

  useEffect(() => {
    let animationFrameId;

    const detectFaces = async () => {
      if (videoRef.current?.readyState >= 2 && detector) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const imgElement = imageRef.current;

        const results = await detector.detectForVideo(video, performance.now());

        if (results.faceLandmarks?.length > 0) {
          setIsFaceDetected(true);
          const [landmarks] = results.faceLandmarks;
          let adjustedLandmarks = adjustLandmarksForRotation(
            landmarks,
            rotation
          );
          const faceWidth = calculateFaceWidth(adjustedLandmarks, canvas);
          const faceHeight = calculateFaceHeight(adjustedLandmarks, canvas);
          animateImage(
            imgElement,
            adjustedLandmarks[10],
            canvas,
            faceWidth,
            faceHeight,
            mirror,
            markRotation
          );
        } else {
          resetImagePosition(imgElement, canvas, markRotation);
          setIsFaceDetected(false);
          sendLog(
            `Face detected at ${new Date().toISOString()} on player ${
              player?.bsFrameId
            }`
          );
        }
      }
      animationFrameId = requestAnimationFrame(detectFaces);
    };

    detectFaces();
    return () => animationFrameId && cancelAnimationFrame(animationFrameId);
  }, [
    detector,
    mirror,
    player,
    rotation,
    markRotation,
    sendLog,
    videoRef,
    canvasRef,
    imageRef,
  ]);

  return isFaceDetected;
};
