import { throttle } from 'throttle-debounce';

// Throttled animation to position the mark
export const updateImagePosition = throttle(150, (imgElement, xPos, yPos, markWidth, markRotation) => {
  if (markRotation === 90 || markRotation === -90) {
    imgElement.style.transform = `translate(${xPos - markWidth / 2}px, ${yPos}px) rotate(${markRotation}deg)`;
  } else {
    imgElement.style.transform = `translate(${xPos - markWidth / 2}px, ${yPos}px)`;
  }
});

export const resetImagePosition = (imgElement, canvas, markRotation) => {
  if (markRotation === 90 || markRotation === -90) {
    imgElement.style.transform = `translate(${canvas.clientWidth / 3}px, ${canvas.clientHeight / 3}px) rotate(${markRotation}deg)`;
  } else {
    imgElement.style.transform = `translate(${canvas.clientWidth / 4}px, ${canvas.clientHeight / 4}px)`;
  }

  updateImageSize(imgElement, 500);
};

export const updateImageSize = throttle(100, (imgElement, markWidth) => {
  imgElement.style.width = `${markWidth.toFixed(0)}px`;
  imgElement.style.height = `${markWidth.toFixed(0)}px`;
});

export const calculateFaceWidth = (landmarks, canvas) => {
  const leftEye = landmarks[33];
  const rightEye = landmarks[263];
  const eyeDistance = Math.sqrt(Math.pow(rightEye.x - leftEye.x, 2) + Math.pow(rightEye.y - leftEye.y, 2));
  return eyeDistance * canvas.width;
};

export const calculateFaceHeight = (landmarks, canvas) => {
  const forehead = landmarks[168];
  const chin = landmarks[10];
  const faceHeight = Math.sqrt(Math.pow(chin.x - forehead.x, 2) + Math.pow(chin.y - forehead.y, 2));
  return faceHeight * canvas.height;
};

export const adjustLandmarksForRotation = (landmarks, rotation) => {
  return landmarks.map(landmark => {
    let { x, y } = landmark;
    if (rotation === 90) {
      [x, y] = [y, 1 - x];
    } else if (rotation === -90) {
      [x, y] = [1 - y, x];
    }
    return { ...landmark, x, y };
  });
};

export const interpolateForeheadX = (foreheadX) => {
  const minX = 0.25, maxX = 0.75;
  return (Math.max(minX, Math.min(foreheadX, maxX)) - minX) / (maxX - minX);
};

export const animateImage = (
  imgElement,
  foreheadLandmark,
  canvas,
  faceWidth,
  faceHeight,
  isMirror,
  markRotation
) => {
  const canvasWidth = canvas.clientWidth;
  const canvasHeight = canvas.clientHeight;

  let interpolatedForeheadX = interpolateForeheadX(foreheadLandmark.x);

  if (isMirror) {
    interpolatedForeheadX = 1 - interpolatedForeheadX;
  }

  let xPos = interpolatedForeheadX * canvasWidth;
  let yPos = foreheadLandmark.y * canvasHeight;

  const markWidth = faceWidth * 5;
  if (markRotation === 90 || markRotation === -90) {
    const xOffset = -faceHeight - markWidth / 2;
    xPos += xOffset;
    const yOffset = -faceWidth - markWidth / 2;
    yPos += yOffset;
  } else {
    const yOffset = -faceHeight * 2 - markWidth;
    yPos += yOffset;
  }

  updateImagePosition(imgElement, xPos, yPos, markWidth, markRotation);
  updateImageSize(imgElement, markWidth);
};