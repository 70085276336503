function ImageElement({ element, faceTrackerImageUrl, visible }) {
  const { style, url, config = {} } = element;

  const { state = {} } = config;

  const { url: stateUrl } = state;

  // const urlFromState = stateUrl && faceTrackerImageUrl

  // useEffect(() => {
  //   if (!visible && urlFromState) {
  //     setCreativeState(prev => ({ ...prev, [stateUrl]: undefined}))
  //   }
  // }, [visible, urlFromState, setCreativeState, stateUrl])

  if (!stateUrl && !url) {
    return <></>
  }

  if (stateUrl && !faceTrackerImageUrl) {
    return <></>
  }

  return <img src={stateUrl ? faceTrackerImageUrl : url} width={style.width} height={style.height} alt="" />;
}

export default ImageElement;
