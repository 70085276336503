import React, { useRef, useEffect } from "react";
import { startCameraVideo } from "helpers";
import { useLogger } from "providers/LoggerProvider";
import { Mark25 } from "assets/fifa-25";
import { useFaceDetection } from "hooks/use-face-detection";
import { useCanvasRendering } from "hooks/use-canvas-rendering";

const FaceDetectionLandMarker = ({ element, player = {} }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const { sendLog } = useLogger();
  const { style, rotation, mirror = true, markRotation = false } = element;
  
  const isFaceDetected = useFaceDetection({
    videoRef,
    canvasRef,
    imageRef,
    rotation,
    mirror,
    markRotation,
    sendLog,
    player,
  });

  useCanvasRendering(canvasRef, videoRef, mirror, rotation, style);

  useEffect(() => {
    function init() {
      startCameraVideo(videoRef.current);
    }
    init();
  }, []);
  

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></video>
      <canvas
        ref={canvasRef}
        width={style.width}
        height={style.height}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      ></canvas>
      <img
        ref={imageRef}
        src={Mark25}
        style={{
          position: "absolute",
          transition: "all 0.3s ease-out",
          width: "500px",
          height: "500px",
          zIndex: 3,
        }}
        alt="face marker"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: isFaceDetected
            ? "rgba(0, 0, 0, 0.2)"
            : "rgba(0, 0, 0, 0.6)",
          transition: "all 0.6s ease-in",
          zIndex: 2,
        }}
      />
    </div>
  );
};

export default FaceDetectionLandMarker;
