import { QRCodeCanvas } from "qrcode.react";

// Usar QRCodeCanvas en lugar de QRCode
<QRCodeCanvas value="https://example.com" />;

export default function QRCodeElement({
  element,
  creativeState = {},
  player = {},
  faceTrackerImageUrl,
}) {
  const { style, url: baseUrl, config } = element;

  const url = config.urlFromState
    ? `${faceTrackerImageUrl}?response-content-disposition=attachment;filename=cocacola-santa.png`
    : new URL(baseUrl);

  const { state = {}, params: playerQueryParams = [] } = config;

  const { params: stateQueryParams = [] } = state;

  if (!url) {
    return <></>;
  }

  stateQueryParams.forEach((param) => {
    const value = creativeState[param.value];

    url.searchParams.append(param.key, value);
  });

  playerQueryParams.forEach((param) => {
    const value = player[param.value];

    url.searchParams.append(param.key, value);
  });

  return (
    <QRCodeCanvas
      value={url.toString()}
      size={style.width}
      renderAs="svg"
      bgColor="white"
      fgColor={style.color}
      style={{ boxSizing: "border-box", border: "5px solid white" }}
    />
  );
}
